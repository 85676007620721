import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { RedirectLoggedGuard } from './core/guards/redirect-logged.guard';
import { AuthGuard } from './core/guards/auth.guard';
import { HotelSelectionGuard } from './core/guards/hotel-selection.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/analytics',
    pathMatch: 'full',
  },
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
    canActivate: [RedirectLoggedGuard]
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard, HotelSelectionGuard],
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
  },
  {
    path: '**',
    redirectTo: '404',
    pathMatch: 'full'
  },
  {
    path: '',
    redirectTo: '/hotel-information',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
