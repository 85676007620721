import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { UserService } from '../services/user.service';

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
  const authenticationService = inject(UserService);

  return next(req).pipe(
    catchError((err) => {
      if (err.status === 401) {
        // auto logout if 401 response returned from api
        console.debug('401 response returned from api. Performing auto logout.');
        authenticationService.logout();
        location.reload();
      }

      const error = err.error.message || err.statusText;
      return throwError(error);
    })
  );
};
