import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserService } from "../services/user.service";
import { DialogService } from "../services/dialog.service";

@Injectable({
  providedIn: 'root',
})
export class RedirectLoggedGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private router: Router,
    private dialogService: DialogService
  ) {}

  canActivate(): boolean {
    if (this.userService.isLoggedIn()) {
      this.dialogService.openHotelSelectionDialog();
      return false;
    }
    return true;
  }
}
