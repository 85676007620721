import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HotelService } from '../services/hotel.service';
import {HotelSelectionDialogComponent} from "../../components/hotel-selection-dialog/hotel-selection-dialog.component";
import {DialogService} from "../services/dialog.service";

@Injectable({
  providedIn: 'root'
})
export class HotelSelectionGuard implements CanActivate {
  constructor(
    private hotelService: HotelService,
    private dialogService: DialogService,
    private router: Router
  ) {}

  canActivate(): Observable<boolean> | boolean {
    const selectedHotel = this.hotelService.getSelectedHotel();
    if (selectedHotel) {
      return true;
    } else {
      return this.dialogService.openHotelSelectionDialog().afterClosed().pipe(
        map((hotelId: string) => {
          if (hotelId) {
            this.hotelService.setSelectedHotel(hotelId);
            return true;
          } else {
            this.router.navigate(['analytics']); // Redirect to home or another appropriate page
            return false;
          }
        })
      );
    }
  }
}
